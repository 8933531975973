<template>
  <div id="proinfo">
    <div class="hadheight"></div>
    <Header />
    <div class="crumbsbox">
        <div class="basecont">
            <router-link to="/">首页</router-link>
            <span>&gt;</span>
            <router-link to="/product">B2P</router-link>
            <span>&gt;</span>
            <router-link :to="{name: 'Productlist',params:{id: proinf.categoryId,title: proinf.categoryName,pan: pan}}">{{proinf.categoryName}}</router-link>
            <span>&gt;</span>
            <a href="javascript:;" class="on">{{proinf.spuName}}</a>
        </div>
    </div>
    <!-- 物品 -->
    <div class="proinfo-sec1 base80">
        <div class="basecont">
            <div class="cont">
                <div class="lt">
                    <div class="f_nav">
                        <div class="proinfo1_nav swiper-container">
                            <div class="swiper-wrapper">
                                <div :class="{'swiper-slide'
                                    :true,on:index==0}" v-for="(item,index) in proshu.img" :key="index">
                                    <div class="img">
                                        <img :src="item.multiUrl" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="proinfo1_cont swiper-container">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" v-for="(item,index) in proshu.img" :key="index">
                                <div class="img">
                                    <img :src="item.multiUrl" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="gt">
                    <div class="tit basefont30">{{proinf.spuName}}</div>
                    <div class="jia">$ {{jiagezon}}</div>
                    <!-- <div class="item dian fcolor1" v-if="proshu.color!=''">
                        <div class="f_tit" @click="imgfazclik($event)">
                            <div class="name">颜色</div>
                            <div class="san"></div>
                        </div>
                        <div class="f_jut">
                            <div class="zi">
                                <div
                                 @click="colorClick(index)"
                                 :class="{li:true,active:index==coloIn,active2:true}"
                                  v-for="(item,index) in proshu.color" 
                                  :key="index">
                                    <span>{{item.attrValue}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item dian" v-if="proshu.chi!=''">
                        <div class="f_tit" @click="imgfazclik($event)">
                            <div class="name">尺寸</div>
                            <div class="san"></div>
                        </div>
                        <div class="f_jut">
                            <div class="zi">
                                <a href="javascript:;" 
                                @click="chiClick(index)"
                                :class="{li:true,active:index==chiIn,active2:item.xuanzhong}" 
                                v-for="(item,index) in proshu.chi" :key="index">
                                    {{item.attrValue}}
                                </a>
                            </div>
                        </div>
                    </div> -->
                    <!-- 尺寸 -->
                    <template v-for="(item,name,index) in prodata.skuSaleAttrValue" >
                        <div class="item dian" :key="index">
                            <div class="f_tit on" @click="imgfazclik($event)">
                                <div class="name">{{name}}</div>
                                <div class="san"></div>
                            </div>
                            <div class="f_jut">
                                <div class="zi">
                                    <template v-for="(it,_in) in item">
                                        
                                        <a href="javascript:;" 
                                            @click="selectProdectClass(name,index,_in)"
                                            :class="{li:true,active:seleIndex[index]==_in,active2:it.checked}" 
                                            :key="_in">
                                                {{it.attrValue}}
                                            </a>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div class="item shu1">
                        <div class="f_tit">
                            <div class="name">数量</div>
                            <div class="fw_zen">
                                <a href="javascript:;" @click="reduce()" class="f_jian">-</a>
                                <input type="number" @change="maxShop()" v-model="liang" >
                                <a href="javascript:;" @click="add()"  class="f_jia">+</a>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="item shu1" v-if="proshu.max">
                        <div class="f_tit">
                            <div class="name">数量</div>
                            <div class="fw_zen">
                                <a href="javascript:;" @click="reduce()" class="f_jian">-</a>
                                <input type="text" @input="maxShop()" v-model="liang" >
                                <a href="javascript:;" @click="add()"  class="f_jia">+</a>
                            </div>
                        </div>
                    </div> -->
                    <div class="but">
                        <!-- @click.prevent="proaltShow()" -->
                        <a href="javascript:;" @click.prevent="joinCart(1)" class="hm-but f_buttom">
                            <span>立即下单</span>
                            <!-- <span>去APP下单</span> -->
                            <i>
                                <img src="../../assets/images/jiantouyo1.png" >
                                <img src="../../assets/images/jiantouyo2.png" >
                            </i>
                        </a>
                        
                        <a href="javascript:;" v-if="!loginToggle" @click.prevent="joinCart()" class="hm-but f_buttom">
                            <span>加入清单</span>
                            <i>
                                <img src="../../assets/images/jiantouyo1.png" >
                                <img src="../../assets/images/jiantouyo2.png" >
                            </i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- CS1 GLOSS BLACK -->
    <!-- <div class="proinfo-sec2 abtbanner">
      <div class="bag">
        <img :src="banner.pcurl" alt="">
        <img :src="banner.mobileurl" alt="">
      </div>
      <div class="cont">
        <div class="basecont">
          <div class="font">
            <div class="tit">
              {{banner.title}}
            </div>
            <a href="javascript:;" :data-video-src="banner.banvideo" class="f_video">
              <i><img src="../../assets/images/videicon1.png" alt=""></i>
              <span>播放视频</span>
            </a>
          </div>
        </div>
      </div>
    </div> -->
    <div class="proinfo-sec2 abtbanner">
      <div class="bag">
        <!-- pc -->
        <img :src="banner2.pcurl" alt="">
        <!-- mobile -->
        <img :src="banner2.mobileurl" alt="">
      </div>
      <div class="audio">
        <audio id="audio" controls src="">
            您的浏览器不支持 audio 元素。
        </audio>
     </div>
      <div class="cont">
        <div class="basecont">
          <div class="font">
            <div class="tit">
              {{proshu.title}}
            </div>
            <template v-if="videoData">
                <a href="javascript:;" :data-video-src="videoData.multiUrl" class="f_video f_video22">
                    <i><img src="../../assets/images/videicon1.png" alt=""></i>
                    <span>播放视频</span>
                </a>
            </template>
            <template v-else>
                <template v-if="auData">
                    <a href="javascript:;" @click="audio1(100)" class="f_video">
                        <i><img src="../../assets/images/videicon1.png" alt=""></i>
                        <span>播放音频</span>
                    </a>
                    <div class="but">
                        <a href="javascript:;" @click="audio1(70)" class="f_but">
                            <i>
                                70%
                            </i>
                            <div class="zi">
                                <p>Moderate</p>
                                <p>Exterior Sound</p>
                            </div>
                        </a>
                        <a href="javascript:;" @click="audio1(50)" class="f_but f_but2">
                            <i>
                                50%
                            </i>
                            <div class="zi">
                                <p>Mild</p>
                                <p>Interior Sound</p>
                            </div>
                        </a>
                    </div>
                </template>
            </template>
          </div>
        </div>
      </div>
    </div>
    <!-- 介绍 -->
    <div class="proinfo-sec3 base100" v-if="spuDesc">
        <div class="basecont">
            <div class="f_text">
                {{spuDesc}}
                <!-- <p>
                    流锻工艺利用古老的技术为今天创造新的突破。锻造是一种使用压缩力塑造金属的制造过程。当部件需要具有高强度时，通常采用这种金属加工技术。当零件在加工过程中被成形和拉伸时，纹理会发生变化以匹配零件的形状，从而使零件具有更高的外力抵抗力。Flow Forged 车轮比铸造或加工的同等零件更坚固。此外，该过程产生的金属废料也更少。这样可以减少浪费在材料上的资金，并节省更多的成本，并为客户提供更多的价值。
                </p>
                <p>
                    <br/>
                </p>
                <p>
                    Blaque Diamond 提供多种宽度选项的新型 Flow Forged BD-F12 车轮以及定制配件计划。该配件计划旨在打造定制和真正的配件，以增强您的异国情调和跑车的外观。BD-F12 车轮尺寸可用于 20 英寸交错凹形配件。
                </p> -->
            </div>
        </div>
    </div>
    <!-- 表格 -->
    <div class="proinfo-sec4 base100" v-if="tabelData!=''">
        <div class="basecont">
            <div class="tit basefont36">产品参数</div>
            <div class="cont">
                <div class="swiper-container proinfo4_cont">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide">
                            <div class="f_text">
                            <table>
                                <thead>
                                    <tr>
                                        <th v-if="tabelData.columnData[0]">
                                            {{tabelData.columnData[0].label}}
                                        </th>
                                        <template v-if="tabelData.attrValueList.length>0">
                                            <th v-for="(item,index) in tabelData.attrValueList" :key="index">
                                                {{item.prop0}}
                                            </th>
                                        </template>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td v-if="tabelData.columnData[1]">{{tabelData.columnData[1].label}}</td>
                                        <template v-if="tabelData.attrValueList.length>0">
                                            <td v-for="(item,index) in tabelData.attrValueList" :key="index">
                                                {{item.prop1}}
                                            </td>
                                        </template>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
                    <div class="swiper-scrollbar proinfo4_scrol"></div>
                </div>
            </div>
        </div>
    </div>
    <!--  产品规格 -->
    <div class="proinfo-sec5 base100" v-if="spec.multiUrl">
        <div class="basecont">
            <div class="tit basefont36">
                 产品规格
            </div>
            <div class="cont">
                <div class="img">
                    <img :src="spec.multiUrl" alt="">
                </div>
                <div class="font" v-html="txtContent"></div>
            </div>
        </div>
    </div>
    <!-- 改装效果 -->
    <div class="proinfo-sec6 base100" v-if="this.effectImage ">
        <div class="basecont">
            <div class="tit basefont36">改装效果</div>
        </div>
        <div class="cont">
            <div class="swiper-button-prev base-prev proinfo6_prev swiper-button-white"></div>
            <div class="swiper-button-next base-next proinfo6_next swiper-button-white"></div>
            <div class="proinfo6_cont swiper-container">
                <div class="swiper-wrapper">
                    <template v-for="item in effectImage">
                        <div class="swiper-slide" :key="item.id">
                            <div class="img">
                                <!-- pc -->
                                <img :src="item.multiUrl" :alt="item.multiName">
                                <!-- 移动 -->
                                <img :src="item.multiUrl" :alt="item.multiName">
                            </div>
                        </div>
                    </template>
                    
                </div>
            </div>
        </div>
    </div>
    <!-- 相关产品 -->
    <div class="proinfo-sec7 base100" v-if="prolist">
        <div class="basecont">
            <div class="f_tit basefont36">
                相关产品
            </div>
            <div class="cont">
                <div class="item" v-for="(item,index) in prolist" :key="index">
                    <div class="img">
                        <img :src="item.spuDefaultImg" :alt="item.spuName">
                    </div>
                    <div class="font">
                        <div class="tit">
                        {{item.spuName}}
                        </div>
                        <div class="text" v-html="item.spuDesc">
                        </div>
                        <div class="jiage">
                        <span class="name">¥ {{item.spuPrice}}</span>
                        <div class="label">
                            <span v-for="(it1,in1) in item.label" :key="in1">{{it1}}</span>
                        </div>
                        </div>
                    </div>
                    <div class="f_jut">
                        <div class="f_icon">
                        <!-- <a href="javascript:;" @click="proaltShow()" class="li"><img src="../../assets/images/gouwucheioncimg1.png" alt=""></a> -->
                        <!-- <a href="javascript:;" @click="shopgouwu($event,item,index)" class="li"><img src="../../assets/images/gouwucheioncimg1.png" alt=""></a> -->
                        <a href="javascript:;" v-if="toglecollect" @click="productStort(index,item.isCollect)" :class="{li:true,shou:true,on:item.isCollect == 0 || item.isCollect == null?false:true}">
                            <img src="../../assets/images/wushouchagn1.png" alt="">
                            <img src="../../assets/images/wushouchagn1-1.png" alt="">
                        </a>
                        <a href="javascript:;" @click="tiaozhuan(item.id)" class="li"><img src="../../assets/images/fgouwuimgf1.png" alt=""></a>
                        </div>
                        <div class="fz_but" @click="proaltShow()">
                        <div class="but">
                            去APP购买
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Swiper from 'swiper';
import "swiper/dist/css/swiper.css";
import * as math from "mathjs";
import { mapActions } from 'vuex';
export default{
  name: "Productlistinfo",
  components:{
    Header,
    Footer,
  },
  data(){
    return {
      proin: false,
      loginToggle: this.$store.state.loginStore.login=="" ||this.$store.state.loginStore.login==null?true:false,
      banner:{},
      banner2:{},
      videoData:"",  //视频数据
      auData: "", //音频 数据
      br2cont:[],
      liang: 1, //数量
      /* 产品 */
      proshu:{ 
          img:[],
          title: "",
          jiage: "",
          color:[],
          width:[],
          kong:[],
          max: 0,
          chi: [],
      },
      selectData:{},
      id: 0,
      proinf:[],
      coloIn: -1,
      chiIn: -1,
      spuDesc: '',
      //   产品规格
      spec:{
        multiUrl: "",
        txtContent: "",
      },
      //  产品参数
      tabelData: [],
      // 改装效果
      effectImage: "",
      //产品推荐
      prolist: "",
      toglecollect: false,
      //判断
      pan: "",
      /* 最新的修改全部的参数在这里 */
      prodata:{}, //产品参数和筛选
      seleIndex: [], //选中的位置
      pricData:{}, //产品价格
    }
  },
//   计算属性
  computed:{
      jiagezon:function(){
          var zong = 0;
          var spu = this.pricData.price || 0;
          zong=math.evaluate(zong+" + ("+this.liang+" * "+Number(spu)+")");	
        return zong;
      },
      crumdata(){
        return this.$route.params;
      }
  },
  methods:{
    ...mapActions(['addStoreCart']),
    proaltShow(){
    //   this.proin = true;
        $(".proinfoAlt").fadeIn();
    },
    proaltHide(){
      this.proin = false;
    },
    add:function(){ //购物车加
        this.liang++;
        if(this.liang>=this.proshu.max){
            this.$message('该产品库存仅剩下'+this.proshu.max);
            this.liang=this.proshu.max;
        }
    },
    reduce:function(){ //购物车减
        this.liang--;
        if(this.liang<=0){
            this.liang=1;
        }
    },
    maxShop:function(){ //购物车最大值
        if(this.liang>=Number(this.proshu.max)){
            this.$message('该产品库存仅剩下'+this.proshu.max);
            this.liang=this.proshu.max;
        }
    },
    formSubmit1:function(){
        // console.log(1);
        // this.$router.push({path:"/confirmorder"});
    },
    getInfo1(){  //获取第一屏图片   和   第二屏的数据
        this.$axios.post("/api2/portal/product/spuInfo/getSpuDetail",{spuId: this.id}).then(res => {
            this.proinf = res.data;
            this.banner = res.data.productSpuMultiVOList;
            
            /* 第一屏图片 */
            if(this.banner.length>0){

  
                this.banner.forEach(v => {
                if(v.multiType==0){
                        this.proshu.img.push(v);
                    }
                });
                let bag1 = res.data.productSpuMultiVOList.find( it => {
                    return it.multiType==0;
                });
                this.banner2.pcurl =bag1.multiUrl;
                this.banner2.mobileurl =bag1.multiUrl;
             
                /* 第二屏视频 */
                let videoIn = this.banner.findIndex(v => {  //视频位置
                    return v.multiType==2;
                });
                 if(videoIn!=-1){  //视频在
                    this.videoData=this.banner[videoIn];
                }else{  //没有视频才显示音频
                    let auIn = this.banner.findIndex(v => {  //音频位置
                        return v.multiType==1;
                    });
                    if(auIn!=-1){
                        this.auData = this.banner[auIn];
                    } 
                }
                this.proshu.jiage = this.proinf.spuPrice;
                this.$nextTick(() => {
                    var wn1 = new Swiper('.proinfo1_nav',{
                        watchOverflow: true,
                        observer: true,
                        observeParents: true,
                        observeSlideChildren:true,
                        speed: 900,
                        freeMode: true,
                        watchSlidesVisibility: true,
                        watchSlidesProgress: true,
                        spaceBetween: 20,
                        observer: true,  //开启动态检查器，监测swiper和slide
                        observeParents: true,  //监测Swiper 的祖/父元素
                        direction : 'vertical',
                        slidesPerView: "auto",
                        breakpoints:{
                            1440:{
                                spaceBetween: 12,
                            },
                            767:{
                                slidesPerView: 5,
                                spaceBetween: 10,
                                direction : 'horizontal',
                            },
                            567: {
                                slidesPerView: 3,
                                spaceBetween: 10,
                                direction : 'horizontal',
                            }
                        }
                    });
                    var galleryTop = new Swiper('.proinfo1_cont', {
                        spaceBetween: 10,
                        observer: true,  //开启动态检查器，监测swiper和slide
                        observeParents: true,  //监测Swiper 的祖/父元素
                        // thumbs: {
                        //     swiper: wn1
                        // },
                        on: {
                            slideChangeTransitionStart: function(){
                                $(".proinfo1_nav .swiper-slide").eq(this.activeIndex).addClass("on").siblings().removeClass("on");
                                wn1.slideTo(this.activeIndex);
                            },
                        },
                    });
                    $(document).on("click",".proinfo1_nav .swiper-slide",function(){
                        galleryTop.slideTo($(this).index());
                    });
                });
                
                


                
            }
            
           

            /* 产品描述 */
            if(res.data.spuDesc){
                this.spuDesc = res.data.spuDesc
            }
            

           

            /* 产品规格 */
            
            if(res.data.productSpuMultiVOList){
                this.spec.multiUrl = res.data.productSpuMultiVOList[2].multiUrl;  //图片
            }
            if(res.data.param){
                let attr1 = JSON.parse(res.data.param).map(it => `<p>${it.key}：${it.value}</p>`);
                this.txtContent = attr1.join("");
            }

            /* 改装效果 */
            if(res.data.modificationSchemeList){
                this.effectImage = res.data.modificationSchemeList;
                this.$nextTick(() => {
                    var wn6 = new Swiper('.proinfo6_cont',{
                        watchOverflow: true,
                        observer: true,
                        observeParents: true,
                        autoHeight: true, //高度随内容变化
                        observeSlideChildren:true,
                        speed: 900,
                        navigation: {
                            nextEl: '.proinfo6_next',
                            prevEl: '.proinfo6_prev',
                        },
                    });
                });
            }
             /* 产品参数 */
            if(res.data.spec){
                this.tabelData = JSON.parse(res.data.spec);
                console.log(this.tabelData);
                this.$nextTick(() => {
                    this.setTableWidth();
                });
            }
            

        }).catch(error => {
            console.log(error);
        });
    },
    getInfo2(){  /* 购买数据 */
        this.$axios.post('/api2/portal/product/skuInfo/getSkuPurchaseInfo',{spuId:this.id}).then(res => {
            if(res.code=="000000"){
                console.log(res);
                // res.data.skuSaleAttrValue["工艺2"] = [{"id":null,"skuId":null,"attrId":null,"attrName":null,"attrValue":"锻造","attrSort":null,"priceMarkup":0}];
                // res.data.skuSaleAttrValue["工艺"] = [{"id":null,"skuId":null,"attrId":null,"attrName":null,"attrValue":"锻造","attrSort":null,"priceMarkup":0},{"id":null,"skuId":null,"attrId":null,"attrName":null,"attrValue":"锻造5","attrSort":null,"priceMarkup":0}];
                let _in = 0; //购买参数的数量
                let fristName; //第一个分类的值
                for(let i in res.data.skuSaleAttrValue){
                    this.seleIndex[_in] = -1;
                    if(_in==0){
                        fristName = i;
                    }
                    _in++;
                    res.data.skuSaleAttrValue[i].forEach((v,_in1) => {
                        v.checked = false;
                    });
                }
                
                this.prodata = res.data;
                this.pricData = res.data.productSkuInfoList[0];
                this.proshu.max = res.data.spuInfo.limitQuantity || 10000;
                this.$nextTick( () => {
                    this.paramsSelect(fristName,0);  //触发第一个参数筛选禁用没有值的按钮
                });
            }
            
            
            // for(let i in res.data.skuSaleAttrValue){
            //     if(i=="尺寸"){
            //         this.proshu.chi = res.data.skuSaleAttrValue[i];
            //     }
            //     if(i=="颜色"){
            //         this.proshu.color = res.data.skuSaleAttrValue[i];
            //     }
            // }
            // this.proshu.chi.forEach((v,_in1) => {
            //     v.xuanzhong = false;
            // });
            // this.selectData = res.data.productSkuInfoList;
            // this.$nextTick(() => {
            //     this.selectPoin();
            // });
            
        }).catch(error => {
            console.log(error);
        });
    },
    /* 参数值点击切换 */
    selectProdectClass(name, index, parIndex){  
        //所选系列的位置name、index 当前点击的位置index
        let skuskuSaleAttrValue = this.prodata.skuSaleAttrValue; //所有参数类别        
        let seleIndex = this.seleIndex;
        if(parIndex==seleIndex[index]){
            seleIndex[index]=-1;
        }else{
            seleIndex[index] = parIndex;
        }
        for(let i = 0;i<seleIndex.length;i++){
            if(i>index){
                seleIndex[i]=-1;
            }
        }
        this.seleIndex=[...seleIndex];
        

        /* 选择后触发参数筛选禁用没有值的按钮 */
        this.paramsSelect(name,index);
    },
    /* 参数筛选 */
    paramsSelect(name,index) {
        //index,name  分类的位置
        let skuskuSaleAttrValue = this.prodata.skuSaleAttrValue; //所有参数类别        
        let skusLeg = 0;  //参数长度
        let seleIndex = this.seleIndex; //选中的位置[]
        let jutSele = seleIndex.every( v => v==-1);
        let productSkuInfoList = this.prodata.productSkuInfoList; //选中后的产品
        for(let i in skuskuSaleAttrValue){
            skusLeg++;
        }
        if(jutSele){ //没有值被选中
            skuskuSaleAttrValue[name].forEach(v => {
                for(let i in productSkuInfoList){
                     if(productSkuInfoList[i].skuSaleAttrFlag.indexOf(v.attrValue) != -1){
                        v.checked=true;
                    }
                }
               
            });
            for(let i in skuskuSaleAttrValue){
                if(i != name){
                    skuskuSaleAttrValue[i].forEach(v => {
                         v.checked=false;
                    });
                }
            }
             this.prodata.skuSaleAttrValue = skuskuSaleAttrValue;
        }else{ //有值被选中
                let selein = 0;
                for(let i in skuskuSaleAttrValue){  //筛选中产品
                    if(selein<=index){
                        if(seleIndex[selein]!=-1){
                            let val = skuskuSaleAttrValue[i][seleIndex[selein]].attrValue; //选中的值
                            productSkuInfoList = productSkuInfoList.filter(v => v.skuSaleAttrFlag.indexOf(val)!=-1);
                        }
                    }
                    selein++;
                }
                let zhua = 0;
                let skuin = index+1;
                for(let i in skuskuSaleAttrValue){  //获取选中状态
                    if(zhua==skuin && index != seleIndex.length-1){
                        skuskuSaleAttrValue[i].forEach(v => {  //改变选中产品的状态
                            for(let x in productSkuInfoList){
                                if(productSkuInfoList[x].skuSaleAttrFlag.indexOf(v.attrValue) != -1){
                                    v.checked=true;
                                }
                            }
                            if(seleIndex[index]==-1){
                                    v.checked=false;
                            }
                        });
                        
                    }
                    
                    zhua++;
                }

        
                this.prodata.skuSaleAttrValue = skuskuSaleAttrValue;


            if(index == seleIndex.length-1){ //选中最后一个
                console.log(productSkuInfoList);
                this.pricData =productSkuInfoList[0];               
            }
            
        }
        
      
        
        

    },
    audio1:function(vol){
        var oVideoBox = $("#audio"); //弹窗
        // 点击显示弹窗，设置视频src，播放视频
        oVideoBox[0].volume = vol/100;
        oVideoBox.attr("src", this.auData.multiUrl);
        oVideoBox.trigger("play");
    },
    // selectPoin(_chiCun){
    //     /* 产品颜色 */
    //     if(this.coloIn !=-1 || this.chiIn != -1){
    //         let proColor = this.proshu.color[this.coloIn].attrValue;
    //         let proChi = this.chiIn==-1?-1:this.proshu.chi[this.chiIn].attrValue;
    //         let colorArray = [];  //所有颜色
    //         let chiArrray = [];  //所有尺寸

            

    //         this.selectData.forEach(v => {
    //             if(v.skuSaleAttrFlag!=null){
    //                 if(v.skuSaleAttrFlag.indexOf(proColor)!=-1){
    //                     colorArray.push(v);
    //                 }
    //             }
    //         });
           
    //         for(let x=0;x<this.proshu.chi.length;x++){
    //             var xuanzhong = false;
    //             for(let y=0;y<colorArray.length;y++){
    //                 if(colorArray[y].skuSaleAttrFlag.indexOf(this.proshu.chi[x].attrValue)==-1){
    //                     xuanzhong=false;
    //                 }else{
    //                     xuanzhong=true;
    //                     chiArrray.push(colorArray[y]);
    //                     break;
    //                 }
    //             }
    //            this.proshu.chi[x].xuanzhong = xuanzhong;
    //         }
    //         if(_chiCun!=undefined){
    //             chiArrray.forEach(v => {
    //                 if(v.skuSaleAttrFlag.indexOf(_chiCun)!=-1){
    //                     this.proinf.spuPrice = v.price;
    //                 }
    //             });
    //         }
           
    //     }else{
    //         this.proshu.chi.forEach((v,_in1) => {
    //             v.xuanzhong = false;
    //         });
    //     }
    //     if(this.coloIn ==-1 && this.chiIn == -1){
    //         this.proinf.spuPrice = this.proshu.jiage;
    //     }
    // },
    /* 颜色选中 */
    // colorClick(_in){
    //     if(_in==this.coloIn){
    //         this.coloIn=-1;
    //     }else{
    //         this.coloIn = _in;
    //         this.chiIn = -1;
    //         this.proinf.spuPrice = this.proshu.jiage;
    //     }
    //     this.selectPoin();
    // },
    /* 尺寸选中 */
    // chiClick(_in){
    //     if(_in==this.chiIn){
    //         this.chiIn=-1;
    //         this.proinf.spuPrice = this.proshu.jiage;
    //         // this.chiac2=-1;
    //     }else{
    //         this.chiIn = _in;
    //         this.selectPoin(this.proshu.chi[this.chiIn].attrValue);
    //     }
    
    // },
    // 表格宽度设置
    setTableWidth (){
        var shu = 7;
        
        if($(window).width()<767){
            shu=3.5;
            if($(".proinfo-sec4 .f_text table tr th").length<3){
                return false;
            }
        }else {
            if($(".proinfo-sec4 .f_text table tr th").length<shu){
                return false;
            }
        }
        
        var _dan = $(".proinfo-sec4 .cont").width()/shu;
        var _width = $(".proinfo-sec4 .f_text table tr th").length*_dan;
        $(".proinfo-sec4 .f_text table tr th").css("width",_dan+"px");
        $(".proinfo-sec4 .f_text table").css("width",_width+"px");
        var wn = new Swiper('.proinfo4_cont',{
            watchOverflow: true,
            observer: true,
            observeParents: true,
            freeMode: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            speed: 900,
            slidesPerView: "auto",
            freeMode: true,
            scrollbar: {
                el: ".proinfo4_scrol",
            },
            mousewheel: true,
      });
    },
    // 收藏
    productStort:function(index,isCollect){  //收藏
      if(isCollect==null || isCollect==1){
        isCollect=0;
      }else{
        isCollect=1;
      }
      let url;
      if(this.$store.state.loginStore.login){
        /* 登录的接口 */
        url = "/api2/portal/auth/product/spuInfo/collectSpu";
      }else{
        /* 没有登录的接口 */
        url = "/api2/portal/product/spuInfo/collectSpu";
      }
      this.$axios.post(url,{
        spuId: this.prolist[index].id,
        type: isCollect
      }).then(res => {
        if(res.code=="000000"){
          if(this.prolist[index].isCollect == 0 || this.prolist[index].isCollect == null){
            this.prolist[index].isCollect=1;
            this.$message({
                message: '收藏成功!',
                type: 'success',
            });
          }else {
            this.prolist[index].isCollect=0;
            this.$message({
                message: '取消收藏!',
                type: 'success',
            });
          }
          
        }
      }).catch(error => {
        console.log(error);
      });
      // this.prolist[index].shou=!this.prolist[index].shou;
    },
    // 跳转
    tiaozhuan(id){
        
        if(id != this.crumdata.id){
                this.$router.push({params:{id:id,id2: this.crumdata.id,title:this.crumdata.title}});
        }else{
            $(window).scrollTop(0);
        }
         
    },
    imgfazclik(e){
        var e = e || event;
        // console.log(e.target);
        var that = $(e.target);
        that.toggleClass("on");
        that.next(".f_jut").stop().slideToggle();
        
    },
    joinCart(duan){  //加入清单
        let jud = this.seleIndex.every(v => v!=-1);
        if(!jud){
            this.$message({
               message: "请选择产品参数！",type: 'error'
            });
            return false;
        }
        let params = {
           skuId: this.pricData.id,
           buyNum: Number(this.liang)
        }
        if(duan==1){
            this.$router.push("/shoppingcart2");
        }
        this.addStoreCart(params);
        
    }
  },
  created() {
    this.pan = this.$route.params.pan;
  },
  watch:{
    liang(newO,or){
        let leth = newO.length;
        if(newO==''){
            this.liang = 0;
        }
        if(leth>8){
            this.$message({
                message: "最多输入八位数！",
                type: 'error',
            });
            this.liang = Number(this.liang.substring(0,8));
            return false;
        }
    }
  },
  mounted(){
    if(this.$store.state.loginStore.login){
      this.toglecollect=true;
    }else{
      this.toglecollect=false;
    }
    this.id = this.$route.params.id;
    this.getInfo1();
    this.getInfo2();
    this.$axios.post('/api2/portal/product/spuInfo/getSimilar',{  //相关产品
        spuId: this.id
    }).then(res => {
        if(res.data.length){
            this.prolist = res.data.data;
        }
    }).catch(error =>{
        console.log(error);
    });
    
    function HiVideoPop(obj) {
        var oVideoBox = $("#hi-video-pop"); //弹窗
        var oClose = oVideoBox.find(".hi-close"); //关闭按钮
        var oVideo = oVideoBox.find("video"); //视频标签
        // 点击显示弹窗，设置视频src，播放视频
        $(document).on("click",obj,function(){
            oVideoBox.fadeIn();
            oVideo.attr("src", $(this).attr("data-video-src"));
            oVideo.trigger("play");
        });
        // 点击关闭弹窗，暂停视频播放
        oClose.click(function() {
            oVideoBox.stop().fadeOut();
            oVideo.trigger("pause");
        });
    }
    setTimeout(function(){
        HiVideoPop(".f_video22");
    },1000);



     
     this.setTableWidth();
  },
}

</script>

