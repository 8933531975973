<template>
  <div id="productlist">
    <div class="hadheight"></div>
    <Header />
    <div class="prolist_banner">
        <div class="img">
            <!-- pc -->
            <img :src="bannerImg" alt="">
            <!-- 移动 -->
            <img :src="bannerImg" alt="">
        </div>
        <a href="javascript:;" class="but">
            <i><img src="../../assets/images/shubiaoicon1.png" alt=""></i>
            <span>滚动</span>
        </a>
    </div>
    <div class="pro_select">
      <div class="basecont">
        <div class="crumbsbox">
            <router-link to="/">首页</router-link>
            <span>&gt;</span>
            <router-link to="/product">P2B</router-link>
            <span>&gt;</span>
            <a href="javascript:;" class="on">{{crumtitle}}</a>
        </div>
        <!-- 品牌分类 -->
          <select name="zhanghu" v-model="proClaz" @change="setBrandParams" class="fz_select" v-show="seleToglle==1">
              <option value="">全部分类</option>
              <option v-for="(item, index) in brandClassSelect" :value="item.id" :key="index">
                  {{item.name}}
              </option>
          </select>
        <!-- 产品分类 -->
          <select name="zhanghu" v-model="proClaz" @change="setProductParams" class="fz_select" v-show="seleToglle==0">
              <option value="">全部分类</option>
              <option v-for="(item, index) in proClassSelect" :value="item.id" :key="index">
                  {{item.name}}
              </option>
          </select>
      </div>
    </div>
    <div class="prolistbox-sec1 base100">
        <div class="basecont">
       
          <ProductList :prolist="prolist" :pan="seleToglle">
          </ProductList>
          
          <el-pagination
                :page-size= limit
                :pager-count = 5
                layout="prev, pager, next"
                :current-page = page 
                :total= total
                @current-change="curpage"
                class="basepage"
                v-show="pageTogle"
                >
            </el-pagination>
        </div>
    </div>
    
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import ProductList from '@/components/product/product-list.vue';
import { re } from 'mathjs';
import { mapMutations} from 'vuex';


export default {

  components: {
    Header,
    Footer,
    ProductList
  },
  data(){
    return {
      bannerImg: '',
      proin: false,
      xie:'',
      prolist:{},
      ball:{
          show:false,
          el:''
      },
      page: 1, //页数
      limit: 9, //条数
      total: 0,
      categoryId: 0,
      pageTogle: false,
      proClaz:  "",
      proClassSelect:[],
      crumtitle: "全部",
      classid: 0,  //当是产品的时候的id
      seleToglle: 1,
      brandfenlist: [],
      brandClassSelect:[],  //品牌下拉列表内容
      pan: ''
    }
  },
  mounted(){
    this.categoryId = this.$route.params.id;
    this.crumtitle = this.$route.params.title;
    this.seleToglle = this.$route.params.pan;
     /* 获取品牌的分类 */
    if(this.$route.params.pan==1){
     this.getBrandselect();
    }else{
      this.getProClassSelect();
    }
    this.getProductList(3);
    /* banner */
    this.$axios.post("/api2/portal/article/adv/findByCondition",{
      advTypeFlag: 2,
      size: 1,
      current: 1,
    }).then( res => {
      this.bannerImg = res.data.data[0].advImgPhone;
    }).catch( error => {
      console.log(error);
    });

   
  },
  computed: {
    crumdata(){
      return this.$route.params;
    },
  },
  watch:{
    $route: {
      handler(val, oldVal){
        this.categoryId = val.params.id;
        this.seleToglle = this.$route.params.pan;

        if(this.$route.params.pan==1){
          this.getBrandselect();
        }else{
            this.getProClassSelect();
        }
        this.getProductList(3);
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {

    proaltShow(){
      $(".proinfoAlt").fadeIn();
    },
    proaltHide(){
      this.proin = false;
    },
    yuanclikc(e){
      this.ball.show = !this.ball.show;
      this.ball.show=true
            //获取点击元素
        this.ball.el=e.target
        // console.log(e,e.target.getBoundingClientRect());
    },
    
    // shopgouwu(e,it,_in1){ //添加购物车
    //   this.$store.commit("addCart",it);
    //   // this.$message("添加成功");
    //   //让我们小球显示出来
    //   // this.ball.show=true
    //   //获取点击元素
    //   // this.ball.el=e.target
    // },
    beforeEnter(el){
            //让小球移动到点击的位置
            //获取点击位置
            const dom=this.ball.el
            const rect=document.querySelector(".f_shopping") //获取点击的dom的位置
            const x=rect.clientTop;
            const y=rect.offsetTop;
            el.style.display='block'
            el.style.top=`${y}px`
            el.style.left=`${x}px`
            const inner=el.querySelector('.inner')
            // inner.style.left=`${x}px`
            // inner.style.top=`${y}px`
    },
    enter(el,done){
        //触发重绘
        document.body.offsetHeight
        //小球移动回到原点，就是购物车的位置
          el.style.top=`0`
          const inner=el.querySelector('.inner')
        inner.style.left=`0`
        //过渡完成后执行的事件
        el.addEventListener('transitionend',done)
    },
    afterEnter(el){
        //结束隐藏小球
        this.ball.show=false
        el.style.display='none'
    },
    curpage(val){
        this.page=val;
        this.getProductList();
    },
    getProductList(selectpan){
      let url;
      if(this.$store.state.loginStore.login){
        /* 登录的接口 */
        url = "/api2/portal/auth/product/spuInfo/findByCondition";
      }else{
        /* 没有登录的接口 */
        url = "/api2/portal/product/spuInfo/findByCondition";
      }
      var data = {};
      if(this.$route.params.pan==1){  
        data = {
          categoryId: this.categoryId,
          brandId: this.proClaz,
          current: this.page,
          size: this.limit,
        }
      }else{ 
        data = {
          categoryId: this.proClaz,
          current: this.page,
          size: this.limit,
          brandId: this.categoryId,
        }
      }
      
      this.$axios.post(url,data).then(res => {
        // console.log(res);
        if(res.data){
          this.prolist = res.data.data;
          this.total = res.data.total;
          if(Math.ceil(this.total/this.limit)<2){
            this.pageTogle=false
          }else{
            this.pageTogle=true
          }
          
        }else{
          this.pageTogle=false
          this.$message({
                message: '没有产品',
                type: 'error',
            });
        }
        
      }).catch(error => {
        console.log(error);
      });
      if(selectpan==0){   
        
      }else if(selectpan==1){
        /* 产品分类 */
        this.crumtitle = this.$route.params.title;
        // this.proClaz = this.$route.params.id;
      } else if(selectpan==3){
        this.crumtitle = this.$route.params.title;
        this.proClaz = "";
      }
    },
    getProClassSelect(){  //获取产品分类
      // this.proClassSelect = _val;
      this.$axios.post('/api2/portal/product/category/findByBrandId',{
         brandId:this.categoryId,
      }).then(res => {
        // console.log(res);
        if(res.data.length){
          this.proClassSelect = res.data;
        }
      }).catch(error => {
        console.log(error);
      });
    },
    setProductParams(){ //设置当前页面产品参数
        let index = this.proClassSelect.findIndex(_in =>{
          return _in.id == this.proClaz;
        });
        if(index>=0){
            let item = this.proClassSelect[index];
            this.proClaz=item.id;
        }else{
          this.proClaz = "";
        }
        this.getProductList(0);
        // this.$router.push({params:{id: item.id,title: item.name}});
    },
    async getBrandselect(){  //获取品牌分类
        await this.$axios.post('/api2/portal/product/brand/findByConditionForPC',{
          "categoryId": this.categoryId,
          firstValue: "",
          secondValue: "",
          current: 1,
          size: 100000,
        }).then(res => {
          // console.log(res);
          if(res.data.data.length){
            this.brandClassSelect = res.data.data;
            // this.classid = res.data[0].id
          }
        }).catch(error => {
          console.log(error);
        });
      
        // this.getProductList();
    },
    setBrandParams(){
      let index = this.brandClassSelect.findIndex(_in =>{
        return _in.id == this.proClaz;
      });
      if(index>=0){
        let item = this.brandClassSelect[index];
        // 获取产品列表数据
        this.proClaz=item.id;
      }else{
        this.proClaz="";
      }
      this.getProductList(1);
    }
  },
  
}
</script>

<style scoped>
.ball{
  position: fixed;
  left: 70%;
  bottom: 10px;
  z-index: 1003;
  color :red;
  width: 20px;
  height: 20px;
  background-color: red;
  transition: all 1s cubic-bezier(0.49,-0.29,0.75,0.41);
}
.fade-enter-active,.fade-leave-active{
  transition: all 0.6s linear;
}
.fade-enter,.fade-leave-to{
  opacity: 0;
}
</style>
