<template>
  <div id="casebox">
    <Header />
    <div class="coperbanner">
        <div class="bag">
            <!-- 清晰 -->
            <img :src="banner.pc" :alt="faqTotle">
            <!-- 模糊 -->
            <img :src="banner.mb" :alt="faqTotle">
        </div>
        <div class="cont">
            <div class="basecont">
                <div class="tit">{{faqTotle}}</div>
                <form action="" class="form" @keyup.prevent.enter="faqCorre()">
                    <input type="text" name="search1" v-model="search1"  :placeholder="faqArray.text.text1">
                    <button type="submit" @click.prevent="faqCorre()" class="f_sub">
                        <img src="../../assets/images/faqiconsearchimg1.png" alt="">
                    </button>
                </form>
            </div>
        </div>
    </div>
    <div class="faqbox-sec1 base80" id="faqbox">
        <div class="basecont">
            <div class="f_tit basefont36">{{faqArray.text.text2}}</div>
            <div class="cont">
                <template v-for="(item,index) in seleList">
                    <div class="item" :key="index">
                        <select name="zhanghu" @change="faqCorre($event,index)" id="">
                            <option value="">{{item.catname}}</option>
                            <option :value="it1.id" v-for="it1 in item.child" :key="it1.id">
                                {{it1.title}}
                            </option>
                        </select>
                    </div>
                </template>
            </div>
        </div>
    </div>
    <div class="faqbox-sec2 base80">
        <div class="basecont">
            <div class="f_tit basefont36">{{faqArray.text.text3}}</div>
            <div class="cont">
                <div :class="`item ${faqzh==index?'on':''}`" v-for="(item, index) in faqcorrelation" :key="index">
                    <div class="tit" @click="faq2Toggle(index)">
                        <span>{{item.title}}</span>
                        <i><img src="../../assets/images/faqwoxiaiconimg1.png" alt=""></i>
                    </div>
                    <div class="f_jut" v-html="item.details"></div>
                </div>
            </div>
            <el-pagination
                :page-size= limit
                :pager-count = 5
                layout="prev, pager, next"
                :current-page = page 
                :total= total
                @current-change="curpage"
                class="basepage"
                v-show="pageToggle"
                >
            </el-pagination>
        </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: "Faq",
  components: {
    Header,
    Footer
  },
  data(){
    return {
      banner:[],
      qie: true,
      faqzh: -1,
      search1: "",
      faqTotle: "",
      clasify:[],
      page: 1, //页数
      limit: 8, //条数
      total: 0,
      faqcorrelation:[], //账户相关内容
      filter1: "",
      filter2: "",
      filter3: "",
      filter4: "",
      filter5: "",
      filter6: "",
      loadtop: false,
      seleList:{},
      faqArray:{
        text:{}
      }
    }
  },
  created:function () {
    
    //   $(document).on("click",".faqbox-sec2 .cont .item .tit",function(){
    //       $(this).parent().toggleClass("on");
    //       $(this).parent().find(".f_jut").stop().slideToggle();
    //       $(this).parent().siblings().find(".f_jut").stop().slideUp();
    //       $(this).parent().siblings().removeClass("on");
    //   });
  },
  mounted:function () {
      
      /* ajax */
      this.$axios.all([this.faqClassify()]).then(res=>{
        /* 分类获取 */
        console.log(res);
        // this.clasify=res[0].data.filter;
        this.faqArray = res[0].data;
        this.banner = res[0].data.banner;
        this.seleList = res[0].data.filter;
        this.faqTotle = res[0].data.catname;
      }).catch(error=>{
        console.log(error);
      });
      this.faqCorre();

  },
  watch:{
    faqzh:function(val,val2){
        // console.log(val,val2);
    }
  },
  computed: {
    pageToggle(){ //判断分页是否隐藏
        return Math.ceil(this.total/this.limit)<=1?false:true;
    }
  },
  methods: {
    
    /* 分类ajax */
    async faqClassify(){
        return await this.$axios.post('/api/Article/getFaqFilterList');
    },
    /* 获取内容 */
    async faqCorre(event,_in){
        var e = e || event;
        var filter = e ? e.target.value : "";
        await this.$axios.post('/api/Article/getFaqList',{
            page: this.page,
            limit: this.limit,
            keyword: this.search1,
            filter: filter,
        }).then(res=>{
            
            this.faqcorrelation = res.data.data;
            this.total = res.data.total;
        }).catch(error=>{
            console.log(error);
        });
        var zong = '';
        $(".faqbox-sec1 .cont .item select").eq(_in).find("option").each(function(){
          if($(this).val()==filter){
            zong = $(this).html();
            return false;
          }
        });
        
        if(zong!=""){
          this.faqTotle = zong;
        }
        /* 数据弄完成执行 */
        this.$nextTick(function(){
            if(this.loadtop){
                const faqTop = document.getElementById("faqbox").offsetTop;
                window.scrollTo(0,faqTop);
            }
            this.loadtop=true;  //滚动到固定位置的开关
        });
    },
    faq2Toggle(_in){
        // console.log();
        // if(this.faqzh==_in){
        //     this.faqzh=-1;
        //     return false;
        // }
        // this.faqzh=_in;
        $(".faqbox-sec2 .cont .item .tit").eq(_in).parent().toggleClass("on");
        $(".faqbox-sec2 .cont .item .tit").eq(_in).parent().find(".f_jut").stop().slideToggle();
        $(".faqbox-sec2 .cont .item .tit").eq(_in).parent().siblings().find(".f_jut").stop().slideUp();
        $(".faqbox-sec2 .cont .item .tit").eq(_in).parent().siblings().removeClass("on");
    },
    curpage(val){
        this.page=val;
        this.faqCorre();
    },
    toggleShu(){
        this.qie=!this.qie;
    }
  }
}
</script>

<style scoped lang="less">
/*
  进入和离开动画可以使用不同
  持续时间和速度曲线。
*/
// .text-fade-enter-active,
// .text-fade-leave-active {
//   transition: opacity 0.5s ease;
// }

// .text-fade-enter-from,
// .text-fade-leave-to {
//   opacity: 0;
// }

/* .bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
} */

.slide-fade-enter-active,.slide-fade-leave-active {
  transition: all .3s ease;
}
// .slide-fade-leave-active {
//  transition: opacity .5s;
// }
.slide-fade-enter,.slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
// .slide-fade-leave-to{
//  opacity: 0;
// }
</style>