<template>
  <div id="home1">
    <Header />
    <!-- 内容 -->
    <section class="hm">
      <!-- banner -->
      <swiper :options="f_banner" class="banbox" ref="mySwiper">
        <swiper-slide v-for="(item, index) in banner" :key="index">
          <a :href="item.link" class="item">
              <!-- pc -->
              <div class="img">
                <!-- 清晰 -->
                <img :src="item.thumb" alt="" />
                <!-- 模糊 -->
                <img :src="item.thumb" alt="" />
              </div>
              <!-- mobile -->
              <div class="img">
                <!-- 清晰 -->
                <img :src="item.picture" alt="" />
                <!-- 模糊 -->
                <img :src="item.picture" alt="" />
              </div>
            </a>
          <!-- <template v-if="item.link=='' && item.link.indexOf('http')!=-1">
            <a :href="item.link" class="item">
              <div class="img">
                <img :src="item.thumb" alt="" />
                <img :src="item.thumb" alt="" />
              </div>
              <div class="img">
                <img :src="item.picture" alt="" />
                <img :src="item.picture" alt="" />
              </div>
            </a>
          </template>
          <template v-else>
            <a :href="item.link" class="item">
              <div class="img">
                <img :src="item.thumb" alt="" />
                <img :src="item.thumb" alt="" />
              </div>
              <div class="img">
                <img :src="item.picture" alt="" />
                <img :src="item.picture" alt="" />
              </div>
            </a>
          </template> -->

        </swiper-slide>
        <div class="swiper-pagination banner-pagin" slot="pagination"></div>
      </swiper>
      <!-- End -->
      <!-- 我们的产品--> 
      <div class="hm-sec1 base80">
        <div class="basecont">
          <div class="cont">
            <div class="hm-tit wow fadeInUp">
              <div class="tit">
                
                <span>{{homeText.text1}}</span>
              </div>
              <div class="text">
                {{homeText.text2}}
              </div>
            </div>
            <swiper
              :options="hm1_cont"
              class="hm1_cont wow fadeInUp"
              ref="mySwiper2"
            >
              <swiper-slide v-for="(item,index) in hm1data.content" :key="index">
                <div class="item">
                  <router-link :to="{name: 'Productlist',params:{pan: 1,id: item.id,title:item.name}}" title="item.title" class="list">
                    <div class="img">
                      <img :src="item.backgroundImg" :alt="item.name" />
                    </div>
                    <div class="f_icon">
                      <i>
                        <img :src="item.pcIcon[0]" />
                        <img :src="item.pcIcon" />
                      </i>
                      <span>{{item.name}}</span>
                    </div>
                  </router-link>
                </div>
              </swiper-slide>
            </swiper>
            <div
              class="swiper-pagination qie hm1_cont-pagin"
              slot="pagination"
            ></div>
          </div>
        </div>
      </div>
      <!-- End-->
      <!-- 汽车系列 -->
      <div class="hm-sec2" v-if="hm2data">
        <!-- <div class="img">
          <img :src="hm2data.images" />
        </div> -->
        <swiper :options="hm2_cont" class="hm2_cont" ref="">
          <swiper-slide v-for="(item,index) in hm2data.centent" :key="index">
            <!-- @mouseleave="xiehover(item.brandBannerPic)" -->
            <div class="cont" :style="'background-image: url('+item.brandPic+')'">
              <router-link :to="{name: 'Brandlistinfo',params: {userid: item.id}}" class="item" >
                <div class="f_tu">
                  <div class="f_icon">
                      <img :src="item.logo" />
                  </div>
                </div>
                <!-- <div class="font">
                  <div class="f_icon">
                    <img :src="item.logo" />
                  </div>
                  <div class="li" v-if="item.descript">
                    <template  v-for="(it, in1) in item.descript.split('#')" >
                            <div class="fzli" :key="in1">{{it}}</div>
                    </template>
                  </div>
                </div> -->
              </router-link>
            </div>
          </swiper-slide>
          <div class="swiper-button-prev swiper-button-white base-prev hm2_prev" slot="button-prev"></div>
          <div class="swiper-button-next swiper-button-white base-next hm2_next" slot="button-next"></div>
        </swiper>
      </div>
      <!-- End -->
      <!-- 汽车性能 -->
      <div class="hm-sec3 base140">
        <div class="img">
          <!-- pc -->
          <img :src="homeText.block3pc" />
          <!-- mobile -->
          <img :src="homeText.block3mb" />
        </div>
        <div class="basecont">
          <div class="font wow fadeInUp">
            <div class="tit">{{homeText.block3subtitle}}</div>
            <div class="f_title">{{homeText.block3title}}</div>
            <router-link to="/product" class="hm-but2 hm-but f_but">
              <span>{{homeText.block3buttext}}</span>
              <i>
                <img src="../assets/images/jiantouyo1.png" />
                <img src="../assets/images/jiantouyo3.png" />
              </i>
            </router-link>
          </div>
        </div>
      </div>
      <!-- End -->
      <!-- 品牌案例 -->
      <div class="hm-sec4 base80">
        <div class="basecont">
          <div class="hm-tit wow fadeInUp">
            <div class="tit">
              <span>{{homeText.text3}}</span>
            </div>
          </div>
          <div class="cont wow fadeInUp">
            <swiper :options="hm4_cont" class="hm4_cont" ref="mySwiper">
              <swiper-slide v-for="(item, index) in hm4cont" :key="index">
                <div class="item">
                  <router-link :to="'/case/caseinfo?id='+item.id" :title="item.title" class="list">
                    <div class="img">
                      <img :src="item.thumb" />
                    </div>
                    <div class="font">
                      <div class="tit">
                        {{ item.title }}
                      </div>
                    </div>
                  </router-link>
                </div>
              </swiper-slide>
            </swiper>
          </div>
          <router-link to="/case" class="hm-but f_but">
            <span>{{homeText.text4}}</span>
            <i>
              <img src="../assets/images/jiantouyo1.png" />
              <img src="../assets/images/jiantouyo2.png" />
            </i>
          </router-link>
        </div>
      </div>
      <!-- End -->
    </section>
    <!-- <Ceshi @childFn="wode" /> -->
    <!-- End -->
    <Footer/>  
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
// import Ceshi from '@/components/Ceshi2.vue'; 
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import { mapGetters,mapState } from 'vuex';

export default {
  name: "home",
  components: {
    swiper,
    swiperSlide,
    Header,
    Footer,
    // Ceshi 
  },
  data() {
    return {
      //swiper
      f_banner: {
        // loop: true,
        observer:true,
        observeParents:true,
        observeSlideChildren:true,
        autoplay: {
          delay: 4000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".banner-pagin", //自动隐藏
          clickable: true,
        },
        // 设置点击箭头
        navigation: {
          nextEl: "",
          prevEl: "",
        },
      },
      
      //我们的产品
      hm1_cont: {
        watchOverflow: true,
        observer: true,
        observeParents: true,
        speed: 900,
        spaceBetween: 20,
        slidesPerView: 2,
        slidesPerColumn: 2,
        slidesPerColumnFill: "row",
        breakpoints: {
          767: {
            spaceBetween: 10,
          },
        },
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".hm1_cont-pagin", //自动隐藏
          clickable: true,
        },
        // 设置点击箭头
        navigation: {
          nextEl: "",
          prevEl: "",
        },
      },
      // 系列图片
      hm2_cont: {
        watchOverflow: true,
        observer: true,
        observeParents: true,
        slidesPerView : 4,
        slidesPerColumn: 2,
        slidesPerColumnFill: 'row',
        speed: 900,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: '.hm2_next',
          prevEl: '.hm2_prev',
        },
        breakpoints: {
          991: {  
            slidesPerView: 3,
          },
          767: {  
            slidesPerView: 2,
          }
        } 
      },
      //品牌案例
      hm4_cont: {
        slidesPerView: 3,
        spaceBetween: 50,
        observer: true,
        observeParents: true,
        breakpoints: {
          1440: {
            spaceBetween: 30,
          },
          991: {
            spaceBetween: 20,
          },
          767: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          567: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
        },
      },
      //banner图数据
      banner: [],
      // 我的产品
      hm1data:[],
      // 汽车系列
      hm2data:{
        images: '',
        centent: []
      },
      // 汽车性能
      hm3data:[],
      // 品牌
      hm4cont:[],
      tog: true,
      homeText:{}
    };
  },

  mounted(){
    var _this = this;
    /* banner ajax */
    function bannerAjax(){
      return _this.$axios.post("/api/Article/getArticleList",{catid:4,fidle:'thumb,picture,link'});
    }
    function hmProduct(){ //我的产品
      return _this.$axios.post("/home/product");
    }
    function hmXilie(){ //产品系列
      return _this.$axios.post("/home/xilie",{wode:"钱"});
    }
    function hmCingNeng(){ //产品性能
      return _this.$axios.post("/homexinneng");
    }
    function hmPingpai(){ //品牌
      // return _this.$axios.post("/homepingpai");
      return _this.$axios.post("/api/Article/getCaseList",{flag:1});
    }

    this.$axios.all([bannerAjax(),hmProduct(),hmXilie(),hmCingNeng(),hmPingpai()]).then(function(bannerPar){
      _this.banner=bannerPar[0].data.data; //banner
      // _this.hm2data = bannerPar[2];//系列产品
      _this.hm3data = bannerPar[3];//产品性能
      _this.hm4cont = bannerPar[4].data.data;//品牌
    }).catch(function(error){
      console.log(error);
    });
    // $(".banbox").css("height",$(window).height()+"px");

    /* 获取首页文案 */
    this.$axios.post('/api/Column/getHomeText').then(res => {
      console.log(res);
      if(res.data){
          this.homeText = res.data;
      }
      
    }).catch(error => {
      console.log(error);
    });

   /* 我的产品 */
    this.$axios.post("/api2/portal/product/category/list",{
      current: 1,
		  size: 8
    }).then(res => {
			this.hm1data.content=res.data.data;
		}).catch(error => {
			console.log(error);
		});

    /* 品牌 */
    this.$axios.post("/api2/portal/product/brand/findByCondition",{
      categoryId: 1
    }).then(res => {
          _this.hm2data.centent = res.data.data;
          _this.hm2data.images = JSON.parse(res.data.data[0].brandBannerPic)[0];
    }).catch(error => {
      console.log(error);
    });

      
    // this.$axios.post("/api/Article/getArticleList",{catid:4,fidle:'thumb,picture'}).then(function(res){
    //   console.log(res);
    //   _this.banner=res.data.data.data; //banner
    // }).catch(function(){
      
    // });
    
    // this.$store.commit("clearcart",2);
    // console.log(this.todos);
  },
  computed:{
    ...mapGetters(['doneTodosCount']),
    ...mapState({
      todos: state => state.Cart.todos,
    }),
  },
  methods:{
    wode(msg){
      console.log(msg);
    },
    toggleClick(){
      this.tog=!this.tog;
      console.log(this.tog);
    },
    xiehover(str){  //汽车系列鼠标经过效果
        console.log(str);
        this.hm2data.images = JSON.parse(str)[0];
    }
  }
};
$(function(){
  $(".hm-sec2 .hm2_cont .cont .item").mouseover(function(){
    var _src = $(this).attr("data-src");
    $(".hm-sec2 .img img").attr("src",_src);
  });
});

</script>
