<template>
    <div class="prolistbox1_cont">
        <div class="item" v-for="(item,index) in prolist" :key="index">
        <div class="fzicon" v-if="item.matchMyCar==1">
            <img src="../../assets/images/remenimgf1.png" alt="">
        </div>
        <div class="img">
            <img :src="item.spuDefaultImg" :alt="item.spuName">
        </div>
        <div class="font">
            <div class="tit">
            {{item.spuName}}
            </div>
            <div class="text" v-html="item.spuDesc">
            </div>
            <div class="jiage">
            <span class="name">¥ {{item.spuPrice}}</span>
            <div class="label">
                <span v-for="(it1,in1) in item.label" :key="in1">{{it1}}</span>
            </div>
            </div>
        </div>
        <div class="f_jut">
            <div class="f_icon">
            <!-- <a href="javascript:;" v-if="toglecollect" @click="shopgouwu(item.id)" class="li">
                <img src="../../assets/images/gouwucheioncimg1.png" alt="">
            </a> -->
            <!-- <a href="javascript:;" @click="proaltShow()" class="li"><img src="../../assets/images/gouwucheioncimg1.png" alt=""></a> -->
            <a href="javascript:;" v-if="toglecollect" @click="productStort(index,item.isCollect)" :class="{li:true,shou:true,on:item.isCollect == 0 || item.isCollect == null?false:true}">
                <img src="../../assets/images/wushouchagn1.png" alt="">
                <img src="../../assets/images/wushouchagn1-1.png" alt="">
            </a>
            <router-link :to="{name: 'Productlistinfo',params:{id:item.id,pan:pan}}"
                class="li"><img src="../../assets/images/fgouwuimgf1.png" alt=""></router-link>
            </div>
            <!-- <div class="fz_but" @click="proaltShow()">
            <div class="but">
                去APP购买
            </div>
            </div> -->
        </div>
        </div>
    </div>
</template>

<script>
import { mapMutations,mapActions } from 'vuex';
export default {
    props:["prolist",'pan'],
    data(){
        return {
            toglecollect: false,
        }
    },
    mounted(){
        if(this.$store.state.loginStore.login){
            this.toglecollect=true;
        }else{
            this.toglecollect=false;
        }
        
    },
    methods:{
        // ...mapMutations({
        //     shopgouwu:"addStoreCart"
        // }),
        ...mapActions(['addStoreCart']),
        productStort(index,isCollect){  //收藏
            if(isCollect==null || isCollect==1){
                isCollect=0;
            }else{
                isCollect=1;
            }
            let url;
            if(this.$store.state.loginStore.login){
                /* 登录的接口 */
                // url = "/api2/portal/auth/product/spuInfo/collectSpu";
                url = "/api2/portal/auth/product/spuInfo/collectSpu";
            }else{
                /* 没有登录的接口 */
                url = "/api2/portal/product/spuInfo/collectSpu";
            }
            this.$axios.post(url,{
                spuId: this.prolist[index].id,
                type: isCollect
            }).then(res => {
                if(res.code=="000000"){
                    if(this.prolist[index].isCollect == 0 || this.prolist[index].isCollect == null){
                        this.prolist[index].isCollect=1;
                        this.$message({
                            message: '收藏成功!',
                            type: 'success',
                        });
                    }else {
                        this.prolist[index].isCollect=0;
                        this.$message({
                            message: '取消收藏!',
                            type: 'success',
                        });
                    }
                
                }
            }).catch(error => {
                console.log(error);
            });
            // this.prolist[index].shou=!this.prolist[index].shou;
        },
        shopgouwu(_id){

            this.addStoreCart({skuId:_id});
        }
    }
}
</script>
