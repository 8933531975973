<template>
  <div id="casebox">
    <Header />
    <div class="coperbanner">
        <div class="bag">
            <!-- pc -->
            <img :src="banner.banner.pc" :alt="banner.title">
            <!-- 移动 -->
            <img :src="banner.banner.mb" :alt="banner.title">
        </div>
        <div class="cont">
            <div class="basecont">
                <div class="tit">
                    {{banner.title}}
                </div>
            </div>
        </div>
    </div>
    <div class="coperbox-sec1 base80">
        <div class="basecont">
            <div class="font">
                <div class="text">{{banner.details}}</div>
            </div>
        </div>
    </div>
    <div class="coperbox-sec2 base80">
        <div class="basecont">
            <div class="tit basefont36">
                {{banner.text.formtitle}}
            </div>
            <form class="cont" id="fzform">
                <div class="item">
                  <select name="modelField[industrytime]" v-model="form.hang1">
                        <option value="">{{banner.text.text1}}</option>
                        <option v-for="(item,index) in banner.text.textarr1" 
                            :key="index" 
                            :value="item">{{item}}</option>
                    </select>
                </div>
                <div class="item">
                    <select name="modelField[num]" v-model="form.ren1">
                        <option value="">{{banner.text.text2}}</option>
                        <option v-for="(item,index) in banner.text.textarr2" 
                            :key="index" 
                            :value="item">{{item}}</option>
                    </select>
                </div>
                <div class="item">
                  <textarea name="modelField[interest]" :placeholder="banner.text.text3" v-model="form.ganxin"></textarea>
                </div>
                <div class="item">
                    <textarea name="modelField[channel]" :placeholder="banner.text.text4" v-model="form.qudao"></textarea>
                </div>
                <div class="item wi3">
                    <input type="text" name="modelField[name]" :placeholder="banner.text.text5" v-model="form.name">
                </div>
                <div class="item wi3">
                    <input type="tel" name="modelField[phone]" :placeholder="banner.text.text6" v-model="form.tel">
                </div>
                <div class="item wi3">
                    <input type="text" name="modelField[email]" :placeholder="banner.text.text7" v-model="form.emaill">
                </div>
                <div class="item">
                    <input type="text" name="modelField[enterprise]" :placeholder="banner.text.text8" v-model="form.fadin1">
                </div>
                <div class="item">
                    <input type="text" name="modelField[xinyong]" :placeholder="banner.text.text9" v-model="form.fadin2">
                </div>
                <div class="item">
                    <input type="text" name="modelField[web]" :placeholder="banner.text.text10" v-model="form.web">
                </div>
                <div class="item">
                    <input type="text" name="modelField[gzphone]" :placeholder="banner.text.text11" v-model="form.dianhu">
                </div>
                <div class="item wi3">
                    <select name="modelField[adressshen]" @change="changeProvince()" v-model="form.sheng">
                        <option value="">{{banner.text.text12}}</option>
                        <option :value="item.value" :data-mu="index" v-for="(item,index) in mapString" :key="index">{{item.label}}</option>
                    </select>
                </div>
                <div class="item wi3">
                    <select name="modelField[addressshi]" @change="changeCity()" v-model="form.shiqu">
                        <option value="">{{banner.text.text13}}</option>
                        <option :value="item.value" :data-mu="index" v-for="(item,index) in cityString" :key="index">{{item.label}}</option>
                    </select>
                </div>
                <div class="item wi3">
                    <select name="modelField[addressqu]" v-model="form.quxian">
                        <option value="">{{banner.text.text14}}</option>
                        <option :value="item.value" :data-mu="index" v-for="(item,index) in districtString" :key="index">{{item.label}}</option>
                    </select>
                </div>
                <div class="item f_dizhi">
                    <input type="text" name="modelField[address]" :placeholder="banner.text.text15" v-model="form.dizhi">
                </div>
                <div class="item f_youbian">
                    <input type="text" name="modelField[postcode]" :placeholder="banner.text.text16" v-model="form.youbain">
                </div>
                <div class="item">
                    <textarea name="modelField[details]" id="" :placeholder="banner.text.text17" v-model="form.yewuxi"></textarea>
                </div>
                <div class="item f_file">
                    <div class="f_jut">
                        <input type="file" name="business" @change="wenjian1()" id="zhidao"  accept="image/gif,image/jpeg,image/jpg,image/png">
                        <div class="img">
                            <img src="" alt="">
                        </div>
                        <div class="zhizhao">
                            <i><img src="../../assets/images/shangchuanimgf1.png" alt=""></i>
                            <span>{{banner.text.text18}}</span>
                        </div>
                    </div>
                </div>
                <div class="item f_file">
                    <input type="hidden" name="id" value="3">
                    <div class="f_jut">
                        <input type="file" name="image" @change="wenjian2()" id="images1" accept="image/gif,image/jpeg,image/jpg,image/png">
                        <div class="img">
                            <img src="" alt="">
                        </div>
                        <div class="zhizhao">
                            <i><img src="../../assets/images/shangchuanimgf2.png" alt=""></i>
                            <span>{{banner.text.text19}}</span>
                        </div>
                    </div>
                    
                </div>
                <div class="item but">
                    <button type="submit" @click.prevent="formSubmit()" class="hm-but f_buttom">
                        <span>{{banner.text.text20}}</span>
                        <i>
                            <img src="../../assets/images/jiantouyo1.png" >
                            <img src="../../assets/images/jiantouyo2.png" >
                        </i>
                    </button>
                </div>
                <div class="item but">
                    <button type="reset" @click="resetImg()" class="hm-but f_buttom">
                        <span>{{banner.text.text21}}</span>
                        <i>
                            <img src="../../assets/images/jiantouyo1.png" >
                            <img src="../../assets/images/jiantouyo2.png" >
                        </i>
                    </button>
                </div>
            </form>
        </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import ditu from '@/assets/js/ditu.json';

export default {
  name: "Cooperation",
  components: {
    Header,
    Footer
  },
  data(){
    return {
       banner:[],
       mapString: ditu, //省份
       cityString: [], //市
       districtString: [], //区
       form:{
           hang1:"",
           ren1:"",
           ganxin: "",
           qudao: "",
           name: "",
           tel:"",
           emaill: "",
           fadin1: "",
           fadin2:"",
           web: "",
           dianhu: "",
           sheng:"",
           shiqu:"",
           quxian:"",
           dizhi:"",
           youbain:"",
           yewuxi:"",
           zhizhao: "",
           images1:""
       }
       
    }
  },
  created:function (){
      
  },
  beforeMount:function(){
    
  },
  mounted:function(){
      var _this = this;
      $(".f_file input[type=file]").change(function(){
            // var imgSrc = _this;
            var imgSize = this.files[0].size;  //b
            if(imgSize>1024*1024*10){//10M
                return alert("上传图片不能超过1M");
            }
            if(this.files[0].type != 'image/png' && this.files[0].type != 'image/jpeg' && this.files[0].type != 'image/gif'){
                return alert("图片上传格式不正确");
            }
            $(this).parents(".f_file").find(".img").show();
            $(this).parents(".f_file").find(".img img").attr("src",_this.getObjectURL($(this).get(0).files[0]));
      });
    /* axios获取banner */
    this.$axios.post('/api/Column/getCooperationInfo').then(res => {
        this.banner = res.data;
        this.banner.title = res.data.catname;
    }).catch(error => {
        console.log(error);
    });
  },
  methods: {
    telCode:function(){
            var re = /^1\d{10}$/;
            if(this.form.tel==""){
                this.$message({
                    message: '手机号码不能为空',
                    type: 'warning',
                });
                return false;
            }
            if(re.test(this.form.tel)){
                return true;
            }else{
                this.$message({
                    message: '手机号码输入错误！',
                    type: 'warning',
                });
                return false;
            }
    },
    wenjian1:function(){
        var file = document.getElementById("zhidao").files[0];
        this.form.zhizhao=file;
    },
    wenjian2:function(){
        var file = document.getElementById("images1").files[0];
        this.form.images1=file;
    },
    formSubmit:function(){  //表单提交
        let reg = /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/
        var _this = this;
        var _for1 = new FormData($("#fzform")[0]);
        if(this.form.hang1==""){
            this.$message.error('请选择相关行业时长！');
            return false;
        }
        if(this.form.ren1==""){
            this.$message.error('请选择在职员工人数！');
            return false;
        }
        if(this.form.ganxin==""){
            this.$message.error('请填写对哪些品牌感兴趣！');
            return false;
        }
        if(this.form.qudao==""){
            this.$message.error('请填写备案方案与销售渠道！');
            return false;
        }
        if(this.form.name==""){
            this.$message.error('您的称呼不能为空！');
            return false;
        }
        if(!this.telCode()){
            return false;
        }
        if(!reg.test(this.form.emaill)){
            this.$message.error('邮箱错误！');
            return false;
        }
        if(this.form.fadin1==""){
            this.$message.error('法定企业名称不能为空！');
            return false;
        }
        if(this.form.fadin2==""){
            this.$message.error('信用代码不能为空！');
            return false;
        }
        if(this.form.web==""){
            this.$message.error('公司网站不能为空');
            return false;
        }
        if(this.form.dianhu==""){
            this.$message.error('请输入正确的工作电话');
            return false;
        }
        if(!/^((\d{3,4})|\d{3,4}-|s)?\d{7,14}$/.test(this.form.dianhu)){
            this.$message.error('请输入正确的工作电话');
            return false;
        }
        
        this.$axios.post('/api3/index/post',_for1).then(function(res){
            if(res.code==1){
                _this.$message({
                    message: '提交成功！',
                    type: 'success'
                });
                for(let x in _this.form){
                    _this.form[x]="";
                }
                $(".coperbox-sec2 .cont .f_file .f_jut .img").hide();
            }else{
                _this.$message.error(res.msg);
            }
        }).catch(function(error){
            console.log(error);
            
        });
    },
    getObjectURL:function(file){ //上传转的图片
        var url = null ;
        if (window.createObjectURL!=undefined) { // basic
            url = window.createObjectURL(file) ;
        } else if (window.URL!=undefined) { // mozilla(firefox)
            url = window.URL.createObjectURL(file) ;
        } else if (window.webkitURL!=undefined) { // webkit or chrome
            url = window.webkitURL.createObjectURL(file) ;
        }
        return url;
    },
    resetImg(){
        $(".f_file .img").hide();
    },
    /* 省份联动 */
    changeProvince(){
        if(this.form.sheng!=""){
            this.mapString.filter(v=>{
                if(v.value==this.form.sheng){
                    this.cityString = v.children;
                    this.form.shiqu="";
                    this.form.quxian="";
                }
            });
        }else{
            this.form.shiqu="";
            this.form.quxian="";
        }
        
    },
    /* 市 */
    changeCity(){
         this.cityString.filter(v=>{
            if(v.value==this.form.shiqu){
               this.districtString = v.children;
               this.form.quxian="";
            }
        });
    }
  }
}
</script>
