<template>
  <div id="contact">
    <div class="hadheight"></div>
    <Header />
    <div class="contact-sec1 base100">
      <div class="basecont">
        <div class="cont">
          <div class="tit basefont30">{{formText.tjqq}}</div>
          <form action="" id="fzform">
            <div class="item">
              <div class="name">{{formText.text1}}</div>
              <select name="modelField[type]" id="" v-model="form.faq">
                <option :value="formText.text2">{{formText.text2}}</option>
                <option :value="item" v-for="(item, index) in hpleArr" :key="index">{{item}}</option>
              </select>
            </div>
            <div class="item">
              <div class="name">{{formText.text3}}</div>
              <input type="text" name="modelField[email]" v-model="form.email">
            </div>
            <div class="item">
              <div class="name">{{formText.text4}}</div>
              <input type="text" name="modelField[theme]" v-model="form.theme">
            </div>
            <div class="item">
              <div class="name">{{formText.text5}}</div>
              <textarea name="modelField[describe]" v-model="form.des" ></textarea>
            </div>
            <input type="hidden" name="id" value="6">
            <label class="zhengce">
              <div class="checkbox1"><input type="checkbox" name="zhengce2" v-model="form.zhence"></div>
              <span><router-link to="/policy">{{formText.text6}}</router-link></span>
            </label>
            <a href="javascript:;" class="hm-but f_sub" @click.prevent="formSubmit()">
              <span>{{formText.text7}}</span>
              <i>
                <img src="../../assets/images/jiantouyo1.png" >
                <img src="../../assets/images/jiantouyo2.png" >
              </i>
            </a>
          </form>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: "contact",
  components: {
    Header,
    Footer
  },
  data(){
    return {
      hpleArr: [],
      form:{
        faq: "一般问题",
        email: "",
        theme: "",
        des:"",
        zhence: "",
      },
      formText:{

      }
    }
  },
  mounted(){
    // 文本
    this.$axios.post("/api/Column/getApplyForm").then(res=>{
      console.log(res);
      this.formText = res.data;
    }).catch(err=>{
      console.log(err);
    });
    this.$axios.post("/api/Column/getConfigType").then(res=>{
      this.hpleArr=res.data;
    }).catch(error=>{
      console.log(error);
    });
  },
  methods: {
    formSubmit:function (){
      let reg = /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/;
      var _for1 = new FormData($("#fzform")[0]);
      if(this.form.faq==""){
        this.$message({
          message: '请选择你要提交的问题！',
          type: 'error'
        });
        return false;
      }
      if(!reg.test(this.form.email)){
            this.$message.error('请输入正确的电子邮件！');
            return false;
        }
      if(this.form.theme==""){
        this.$message({
          message: '主题不能为空！',
          type: 'error'
        });
        return false;
      }
      if(!this.form.zhence){
        this.$message({
          message: '请同意隐私政策！',
          type: 'error'
        });
        return false;
      }
      this.$axios.post('/api3/index/post',_for1).then(res=>{
          console.log(res);
          if(res.code==1){
              this.$message({
                message: '提交成功！',
                type: 'success'
              });
              setTimeout(function(){
                window.location.reload();
              },200);
          }
      }).catch(error=>{
        console.log(error);
      });
      return false;
    }
  }
}
</script>
